import {
  Button,
  CircularProgress,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { patch, post, update } from "../service/apiService";
import ConfirmSnackBar from "../components/snackbar/ConfrimSnackBar";

function Support() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [error, setError] = useState<string>("");
  const snackSuccessText = "تم إرسال الرسالة لفريقنا";
  const [loading, setLoading] = useState<boolean>(false);

  const [confirmSubmit, setConfrimSubmit] = useState<boolean>(false);

  const sendMessage = () => {
    setLoading(true);
    post("/Support", { phoneNumber, email, message, subject })
      .then((res) => {
        setLoading(false);
        setConfrimSubmit(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center mb-5">
        <div className="survey-container">
          <h3 className="text-center mt-5">الدعم</h3>
          <div className="info-body">
            <div className="w-100">
              <div className="mandatory-star ">*</div>
              <TextField
                id="outlined-read-only-input"
                className="mb-5 w-100"
                label="رقم الهاتف المحمول"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <TextField
              id="outlined-read-only-input"
              className="mb-5"
              label="البريد الإلكتروني"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="w-100">
              <div className="mandatory-star ">*</div>
              <TextField
                id="outlined-read-only-input"
                className="mb-5 w-100"
                label="الموضوع"
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="w-100">
              <div className="mandatory-star ">*</div>
              <TextareaAutosize
                minRows={3}
                aria-label="empty textarea"
                className="w-100"
                placeholder="الرسالة"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <p className="text-danger"> {error}</p>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={sendMessage}
                variant="contained"
                color="success"
                className=" mt-5 text-white"
              >
                إرسال
              </Button>
            )}
          </div>
          <ConfirmSnackBar
            open={confirmSubmit}
            text={snackSuccessText}
            position={{ vertical: "top", horizontal: "center" }}
          />
        </div>
      </div>
      <style>{`
      [dir="rtl"] .MuiFormLabel-root {
  left: auto;
  right: auto;
  transform-origin: top right;
  text-align: right;
  margin-right: 25px;
}

[dir="rtl"] .MuiOutlinedInput-notchedOutline {
  text-align: right;
}

    
          `}</style>
    </>
  );
}

export default Support;
