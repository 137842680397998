import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(props.open);
  const { handleCancel } = props;
  const [text, setText] = React.useState("هل متأكن من إلغاء الإشتراك ؟");
  const [title, setTitle] = React.useState("إلغاء الإشتراك");

  const handleClose = () => {
    setOpen(false);
  };

  const onAgree = () => {
    handleCancel();
    setOpen(false);
  };
  console.log("props", props);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAgree} autoFocus>
            متأكد
          </Button>
          <Button onClick={handleClose}>لست متأكد</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
